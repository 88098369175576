import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useEventsMutation } from 'queries/global';
import { usePreferences } from 'hooks/use-preferences';
import type { NotificationPreference } from 'types';
import ArtistUploadNotification from 'components/notifications/ArtistUploadNotification';
import DjBookingNotification from 'components/notifications/DjBookingNotification';
import MusicMixNotification from 'components/notifications/MusicMixNotification';
import { useNotification } from 'hooks/use-notification';

type Props = {
    open: boolean;
    onClose: () => void;
};

const StyledWrapper = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '16px',
        margin: '0'
    },

    '& .MuiContainer-root': {
        padding: '0',

        '& .MuiDialog-root': {
            backgroundColor: 'black'
        }
    }
});

const dialogs = [
    <ArtistUploadNotification key='artist-upload' />,
    <DjBookingNotification key='dj-booking' />,
    <MusicMixNotification key='music-mix' />
];

const NotificationWrapper = ({ open, onClose, ...props }: Props) => {
    const { mutateAsync } = useEventsMutation();
    const { currentNotification } = useNotification();
    const [getPreference, setPreference] = usePreferences();
    const notifications = getPreference<NotificationPreference[]>(
        'notification.dialogs',
        []
    );

    const name = currentNotification;
    const dialogComponent = dialogs.find(
        dialog => dialog.key === currentNotification
    );

    // save notification when it is opened
    useEffect(
        () => {
            if (name && open) {
                setPreference<NotificationPreference[]>(
                    'notification.dialogs',
                    [
                        ...notifications,
                        {
                            event: name
                        }
                    ]
                );

                trackEvent('view');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, open]
    );

    const trackEvent = (event: string) => {
        if (name) {
            mutateAsync({
                event: 'notification:dialog:activity',
                data: {
                    name: name,
                    type: event
                }
            });
        }
    };

    useEffect(
        () => {
            if (name && open) {
                trackEvent('view');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, open]
    );

    const onCloseClick = () => {
        trackEvent('close');
        onClose?.();
    };

    return (
        <StyledWrapper
            open={open && currentNotification !== undefined}
            onClose={onCloseClick}
        >
            {dialogComponent}
        </StyledWrapper>
    );
};

export default NotificationWrapper;
