import merge from 'lodash/merge';
import getByKey from 'lodash/get';
import setByKey from 'lodash/set';
import type {
    AccountPreferences,
    AccountPreferencesPaths,
    DevicePreferences,
    DevicePreferencesPaths,
    Preferences,
    PreferenceType,
    UserId
} from 'types';

const VERSION = '0.0.1';

export const combinePreferences = (
    device?: DevicePreferences,
    account?: AccountPreferences
): Preferences => {
    return merge({}, account, device);
};

export const accountPreferencesKeys: AccountPreferencesPaths[] = [
    'player.repeat',
    'feedback.dialog'
];

export const devicePreferencesKeys: DevicePreferencesPaths[] = [
    'shell.theme',
    'player.volume',
    'player.autoplay',
    'notification.dialogs',
    'music-mix.query',
    'music-mix.result'
];

export const getPreferenceValuesFor = <T>(
    preferences: Preferences,
    type: PreferenceType
): T => {
    const result: Partial<T> = {};

    const keys =
        type === 'account' ? accountPreferencesKeys : devicePreferencesKeys;

    keys.forEach(key => {
        const values = getByKey(preferences, key);

        setByKey(result, key, values);
    });

    return result as T;
};

export const getIsAccountKey = (key: AccountPreferencesPaths) => {
    return accountPreferencesKeys.includes(key as AccountPreferencesPaths);
};

export const getIsDeviceKey = (key: DevicePreferencesPaths) => {
    return devicePreferencesKeys.includes(key as DevicePreferencesPaths);
};

export const getPreferencesVersion = () => {
    return VERSION;
};

export const getPreferencesKey = (userId: UserId | null) => {
    return `preferences.${userId ?? 'guest'}`;
};
