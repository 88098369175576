import { compile } from 'path-to-regexp';
import type {
    UseMutationOptions,
    UseQueryOptions
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import axios from 'overrides/axios';
import type {
    AppEvent,
    Artist,
    Country,
    CountryId,
    State,
    Database,
    DatabaseDjItem,
    Session,
    BrowserId,
    FeedbackMessage,
    PushSubscription,
    SubscriptionTopic,
    PushMessage
} from 'types';
import type { FetchResponseError } from 'types/fetch';
import { getBrowserId } from 'helpers/app';
import queryClient from 'shared/query-client';
import URIS from './uris.json';

const getCountryStatesUrl = compile<{ countryId: string }>(
    `${URIS.countries.states}`
);

const getPushSubscriptionUrl = compile<{ browserId?: BrowserId }>(
    `${URIS.push.subscriptions}`
);

export const globalQueryKeys = {
    COUNTRIES: 'countries',
    COUNTRY_STATES: 'country.states',
    DATABASE: 'database',
    EVENTS: 'events',
    DATABASE_INDEXED: 'database.indexed',
    PUSH_SUBSCRIPTION: 'push.subscriptions'
};

export const databaseQuery = {
    queryKey: [globalQueryKeys.DATABASE],
    queryFn: async () => {
        const { data } = await axios.get<Database>(URIS.database, {
            timeout: 60 * 1000
        });

        return {
            djs: data.djs.map((dj: DatabaseDjItem) => {
                return {
                    ...dj,
                    type: 'dj'
                };
            }),
            artists: data.artists
                .sort((a: Artist, b: Artist) => {
                    if (a.stageName > b.stageName) return 1;

                    if (b.stageName > a.stageName) return -1;

                    return 0;
                })
                .map((artist: Artist) => {
                    return {
                        ...artist,
                        type: 'artist'
                    };
                })
        };
    },
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24
};

export const useDatabase = (slim = true) => {
    const { data } = useQuery<Database, FetchResponseError, Database>(
        databaseQuery
    );
    const [database, setDatabase] = useState(data);

    useEffect(() => {
        if (slim && data) {
            setDatabase({
                djs: data.djs,
                artists: data.artists.map(item =>
                    omit(item, ['albums', 'akas'])
                ) as Omit<Artist, 'akas' | 'albums'>[]
            });
        } else {
            setDatabase(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return database;
};

export const useCountriesQuery = (
    options?: UseQueryOptions<Country[], FetchResponseError>
) => {
    return useQuery<Country[], FetchResponseError>({
        queryKey: [globalQueryKeys.COUNTRIES],
        queryFn: async ({ signal }) => {
            const response = await axios.get<Country[]>(URIS.countries.index, {
                signal
            });

            return response.data;
        },
        ...options,
        staleTime: Infinity
    });
};

export const useCountryStatesQuery = (countryId: CountryId) => {
    const url = getCountryStatesUrl({
        countryId: String(countryId)
    });

    return useQuery<State[], FetchResponseError>({
        queryKey: [globalQueryKeys.COUNTRY_STATES, { countryId }],
        queryFn: async ({ signal }) => {
            const response = await axios.get<State[]>(url, {
                signal
            });

            return response.data;
        },
        enabled: Boolean(countryId),
        staleTime: Infinity
    });
};

export const getEvents = async () => {
    const response = await axios.get<AppEvent[]>(URIS.events);

    return response.data;
};

export const useEventsQuery = () => {
    return useQuery<AppEvent[], FetchResponseError>({
        queryKey: [globalQueryKeys.EVENTS, 'events'],
        queryFn: getEvents
    });
};

export const createEvent = async (data: AppEvent) => {
    const response = await axios.post<AppEvent>(URIS.events, data);

    return response.data;
};

export const useEventsMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, AppEvent>
) => {
    return useMutation({
        ...options,
        mutationFn: createEvent
    });
};

export const updateOrCreateSession = async () => {
    const response = await axios.put<Session>(URIS.sessions, {
        timeout: 60 * 1000
    });

    return response.data;
};

export const useSessionMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, Session>
) => {
    return useMutation({
        ...options,
        mutationFn: updateOrCreateSession
    });
};

export const getPushToken = async () => {
    const browserId = getBrowserId();

    const response = await axios.get<string>(
        getPushSubscriptionUrl({
            browserId
        })
    );

    return response.data;
};

export const savePushToken = async (token: string) => {
    const browserId = getBrowserId();

    const response = await axios.put(
        getPushSubscriptionUrl({
            browserId
        }),
        {
            token
        }
    );

    queryClient.invalidateQueries([globalQueryKeys.PUSH_SUBSCRIPTION]);

    return response.data;
};

export const deletePushToken = async () => {
    const browserId = getBrowserId();

    const response = await axios.delete(
        getPushSubscriptionUrl({
            browserId
        })
    );

    queryClient.invalidateQueries([globalQueryKeys.PUSH_SUBSCRIPTION]);

    return response.data;
};

export const sendFeedback = async (data: FeedbackMessage) => {
    const response = await axios.post<FeedbackMessage>(
        URIS.users.feedback,
        data
    );

    return response.data;
};

export const useFeedbackMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, FeedbackMessage>
) => {
    return useMutation(sendFeedback, options);
};

export const usePushSubscriptionsQuery = (
    options?: UseQueryOptions<PushSubscription, FetchResponseError>
) => {
    const browserId = getBrowserId();

    return useQuery<PushSubscription, FetchResponseError>({
        queryKey: [globalQueryKeys.PUSH_SUBSCRIPTION],
        queryFn: async () => {
            const response = await axios.get<PushSubscription>(
                getPushSubscriptionUrl({
                    browserId
                })
            );

            return response.data;
        }
    });
};

export const useSubscribeToTopicMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, SubscriptionTopic>
) => {
    const browserId = getBrowserId();

    return useMutation({
        ...options,
        mutationFn: async data => {
            const response = await axios.post(
                getPushSubscriptionUrl({
                    browserId
                }),
                data
            );

            return response.data;
        },
        onMutate: data => {
            queryClient.setQueryData([globalQueryKeys.PUSH_SUBSCRIPTION], data);
        },
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [globalQueryKeys.PUSH_SUBSCRIPTION],
                type: 'active'
            });
            queryClient.invalidateQueries([globalQueryKeys.PUSH_SUBSCRIPTION]);
        }
    });
};

export const usePushMessageMutation = (
    options?: UseMutationOptions<{}, FetchResponseError, PushMessage>
) => {
    return useMutation({
        ...options,
        mutationFn: async message => {
            const response = await axios.post(URIS.push.messages, message);

            return response.data;
        }
    });
};
