/* global cast, chrome*/
import { captureException } from 'overrides/sentry';
import logger from 'utilities/logger';
import {
    getTrackAlbumArtistsText,
    getTrackFile,
    getTrackImage
} from 'helpers/track';
import type { Track, PlayerRepeat, TrackId } from 'types';
import config from 'config';
import {
    getController,
    getContext,
    seek,
    stop,
    getCurrentTime,
    getIsConnected,
    getDuration,
    setVolume
} from 'shared/cast-player';
import type {
    PlayerInterface,
    PlayerOptions,
    LoadOption,
    ShuffleOption
} from './type';

const receiverApplicationId = config.CHROMECAST_APP_ID;

class CastPlayer implements PlayerInterface<chrome.cast.media.QueueItem> {
    context: cast.framework.CastContext;
    controller: cast.framework.RemotePlayerController;
    options: PlayerOptions;
    isResumed: boolean;
    tracks: chrome.cast.media.QueueItem[] = [];
    currentIndex = 0;
    skipStartIndex = -1;

    sessionId: string | null = null;
    constructor(options: PlayerOptions) {
        const {
            // onError,
            // onReady,
            // playlist,
            onLoading,
            onBufferChange,
            onCastStateChange,
            onCastConnect,
            onCastDisconnect,
            onPaused,
            onPlaying,
            onPlaylistItem,
            onStop
        } = options;

        this.isResumed = false;

        const context = getContext();
        const autoJoinPolicy = chrome.cast.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED;

        context.setOptions({
            receiverApplicationId,
            autoJoinPolicy
        });

        const { CAST_STATE_CHANGED, SESSION_STATE_CHANGED } =
            cast.framework.CastContextEventType;

        context.addEventListener(CAST_STATE_CHANGED, e => {
            logger.debug(`${e.type}:${e.castState}`);
            onCastStateChange({
                value: e.castState
            });
        });

        context.addEventListener(SESSION_STATE_CHANGED, e => {
            logger.debug(`${e.type}:${e.sessionState}:${e.errorCode}`);
        });

        const controller = getController();
        const {
            // ANY_CHANGE,
            IS_CONNECTED_CHANGED,
            IS_MEDIA_LOADED_CHANGED,
            MEDIA_INFO_CHANGED,
            PLAYER_STATE_CHANGED
        } = cast.framework.RemotePlayerEventType;

        controller.addEventListener(
            IS_CONNECTED_CHANGED,
            (e: cast.framework.RemotePlayerChangedEvent<boolean>) => {
                if (e.value) {
                    const session = this.getCurrentSession();

                    if (session) {
                        this.isResumed =
                            session.getSessionState() ===
                            cast.framework.SessionState.SESSION_RESUMED;
                        const deviceName = session.getCastDevice().friendlyName;

                        onCastConnect({ deviceName });
                    }
                } else {
                    onCastDisconnect();
                }
            }
        );

        // controller.addEventListener(
        //     ANY_CHANGE,
        //     (e: cast.framework.RemotePlayerChangedEvent<any>) => {
        //         if (e.field !== 'currentTime') {
        //             logger.debug(`${e.field}: ${JSON.stringify(e)}`, e);
        //         }
        //     }
        // );

        controller.addEventListener(
            PLAYER_STATE_CHANGED,
            (
                e: cast.framework.RemotePlayerChangedEvent<chrome.cast.media.PlayerState>
            ) => {
                const { value } = e;

                logger.debug(`cast.state: ${value}`);

                if (value === chrome.cast.media.PlayerState.PLAYING) {
                    onPlaying();
                } else if (value === chrome.cast.media.PlayerState.PAUSED) {
                    onPaused();
                } else if (value === chrome.cast.media.PlayerState.BUFFERING) {
                    onLoading();
                } else {
                    onStop();
                }
            }
        );

        controller.addEventListener(
            MEDIA_INFO_CHANGED,
            (
                e: cast.framework.RemotePlayerChangedEvent<chrome.cast.media.MediaInfo>
            ) => {
                const mediaInfo = e.value;

                logger.debug(`cast.mediaInfoChanged: ${mediaInfo?.contentId}`);

                if (mediaInfo?.contentId) {
                    onPlaylistItem({
                        trackId: parseInt(mediaInfo.contentId) as TrackId
                    });
                }
            }
        );

        controller.addEventListener(IS_MEDIA_LOADED_CHANGED, e => {
            if (e.value) {
                onBufferChange({ bufferPercent: 100 });
            }
        });

        this.controller = controller;
        this.context = context;
        this.options = options;
    }

    getMediaInfo(track: Track) {
        const mediaInfo = new chrome.cast.media.MediaInfo(
            track.id.toString(),
            'audio/mpeg'
        );

        // @ts-ignore: partially typed external library
        mediaInfo.contentUrl = getTrackFile(track).replace('.internal', '.ng');

        mediaInfo.metadata = new chrome.cast.media.MusicTrackMediaMetadata();
        mediaInfo.metadata.title = track.title;
        // mediaInfo.metadata.albumArtist = track.artist.stageName;
        mediaInfo.metadata.albumName = track.album?.title;
        mediaInfo.metadata.artist = getTrackAlbumArtistsText(track);
        mediaInfo.metadata.trackNumber = track.no;

        const { album, artist } = track;

        if (album) {
            const trackImageOption = {
                album,
                artist
            };

            mediaInfo.metadata.images = [
                {
                    url: getTrackImage(trackImageOption, 'large').replace(
                        '.internal',
                        '.ng'
                    ),
                    height: '500px',
                    width: '500px'
                },
                // {
                //     url: getTrackImage(trackImageOption, 'thumb'),
                //     height: '100px',
                //     width: '100px'
                // },
                {
                    url: getTrackImage(trackImageOption).replace(
                        '.internal',
                        '.ng'
                    ),
                    height: '200px',
                    width: '200px'
                }
            ];
        }
        // mediaInfo.customData = track.customData;
        // mediaInfo.contentUrl = item.file;

        return mediaInfo;
    }

    toQueueItem(track: Track) {
        const mediaInfo = this.getMediaInfo(track);
        const item = new chrome.cast.media.QueueItem(mediaInfo);

        item.autoplay = true;

        return item;
    }

    getQueueLoadRequest(
        items: chrome.cast.media.QueueItem[],
        { currentItemIndex, position, repeat }: LoadOption
    ) {
        const request = new chrome.cast.media.QueueLoadRequest(items);

        request.customData = {
            test: 'test',
            shuffle: false
        };
        request.repeatMode = this.getRepeatMode(repeat);
        request.startIndex = currentItemIndex;
        // @ts-ignore: partially typed thirdparty library
        request.currentTime = position;

        // request.autoplay = false;
        return request;
    }

    getRepeatMode(repeat: PlayerRepeat) {
        switch (repeat) {
            case 'all':
                return chrome.cast.media.RepeatMode.ALL;
            case 'one':
                return chrome.cast.media.RepeatMode.SINGLE;
            default:
                return chrome.cast.media.RepeatMode.OFF;
        }
    }

    getCurrentSession() {
        return this.context.getCurrentSession();
    }

    load(playlist: Track[], options: LoadOption) {
        if (!Array.isArray(playlist)) {
            return;
        }

        const items = playlist.map(track => this.toQueueItem(track));

        if (!getIsConnected() || items.length === 0) {
            return;
        }

        this.tracks = items;
        this.currentIndex = options.currentItemIndex;

        if (this.sessionId !== null) {
            chrome.cast.requestSessionById(this.sessionId);
        }

        const session = this.getCurrentSession();

        if (session) {
            const sessionObject = session.getSessionObj();
            const queueLoadRequest = this.getQueueLoadRequest(items, options);

            sessionObject.queueLoad(
                queueLoadRequest,
                media => {
                    this.sessionId = session.getSessionId();
                    logger.debug('Load succeed', media);
                },
                captureException
            );
        } else {
            logger.error(
                'Error: Attempting to play media without a Cast Session'
            );
        }
        // logger.debug('@queue', queue);
        // this.play();
    }

    play({ trackId }: { trackId?: number } = {}) {
        if (trackId) {
            this.sendMessageToReceiver('QUEUE_UPDATE', {
                currentItemId: trackId
            });
        } else {
            this.controller.playOrPause();
        }
    }

    pause() {
        this.controller.playOrPause();
    }

    stop() {
        stop();
    }

    seek(seekTime: number) {
        seek(seekTime);
    }

    shuffle({ trackIndex }: ShuffleOption<chrome.cast.media.QueueItem>) {
        this.sendMessageToReceiver('QUEUE_SHUFFLE');

        logger.debug('@shuffle', trackIndex);
    }

    getState() {
        return {};
    }

    next() {
        this.sendMessageToReceiver('QUEUE_NEXT');
    }

    prev() {
        this.sendMessageToReceiver('QUEUE_PREV');
    }

    sendMessageToReceiver(type: string, data?: object) {
        const session = this.getCurrentSession();

        if (session) {
            const media = session.getMediaSession();

            if (media) {
                const requestId = Math.ceil(Math.random() * 100);
                const mediaSessionId = media.mediaSessionId;

                logger.debug(`@message|${type}|${requestId}|begin`);

                session
                    .sendMessage('urn:x-cast:com.google.cast.media', {
                        type,
                        requestId,
                        mediaSessionId,
                        ...data
                    })
                    .then(message => {
                        logger.debug('message sent', message);
                    })
                    .catch(captureException)
                    .finally(() => {
                        logger.debug(`@message|${type}|${requestId}|end`);
                    });
            }
        }
    }

    getMediaSession() {
        const session = this.getCurrentSession();

        if (session) {
            return session.getMediaSession();
        }
    }

    getPosition() {
        return getCurrentTime();
    }

    getDuration() {
        return getDuration();
    }

    getIsResumed() {
        return this.isResumed;
    }

    repeat(value: PlayerRepeat) {
        const media = this.getMediaSession();

        if (media) {
            let repeat: chrome.cast.media.RepeatMode;

            switch (value) {
                case 'all':
                    repeat = chrome.cast.media.RepeatMode.ALL;
                    break;
                case 'one':
                    repeat = chrome.cast.media.RepeatMode.SINGLE;
                    break;
                case 'none':
                default:
                    repeat = chrome.cast.media.RepeatMode.OFF;
            }
            media.queueSetRepeatMode(repeat, logger.debug, logger.error);
        }
    }

    volume(value: number) {
        setVolume(value);
    }

    getItems() {
        return this.tracks;
    }

    getId() {
        return this.getItems()[this.currentIndex]?.itemId;
    }

    add(tracks: chrome.cast.media.QueueItem[]) {
        // TODO [2024-10-30]: to be implemented
        logger.debug('@add', tracks);
    }

    remove(trackId: number) {
        // TODO [2024-10-30]: to be implemented
        logger.debug('@remove', trackId);
    }
}
export default CastPlayer;
