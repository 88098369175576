import DjBookings from 'pages/settings/pages/dj/dashboard';
import MusicMix from 'pages/music-mix';
import Dashboard, {
    Music as DashboardMusic,
    Users as DashboardUsers,
    Contents as DashboardContents,
    PushNotifications as DashboardPushNotifications
} from 'pages/settings/pages/dashboard';
import type { LocationState, PageRoute, ShowId } from 'types';
import { getShowPreviewUri } from 'helpers/uri';
import About from 'pages/about';
import Activation from 'pages/activation';
import Album, { albumLoader } from 'pages/albums/album';
import Albums from 'pages/albums';
import Artists from 'pages/artists';
import Artist from 'pages/artists/artist';
import ArtistVerification from 'pages/artists/artist/pages/account-verification';
import Browse from 'pages/browse';
import Genres from 'pages/genres';
import Cart from 'pages/cart';
import CartPayment from 'pages/cart/pages/payment';
import Dj from 'pages/djs/dj';
import Djs from 'pages/djs';
import DjBooking from 'pages/settings/pages/dj/bookings';
import BookingPayout from 'pages/settings/pages/dj/payout';
import Genre from 'pages/genres/genre';
import Discover from 'pages/discover';
import Faq from 'pages/faq';
import ForgotPassword from 'pages/forgot-password';
import Home, { homeLoader } from 'pages/home';
import Library from 'pages/library';
import Login from 'pages/login';
import Profile from 'pages/settings/pages/profile';
import RecentlyAdded from 'pages/recently-added';
import { Playlist, UserOrDjPlaylist } from 'pages/playlists';
import Recommended from 'pages/recommended';
import Recents from 'pages/recents';
import Register from 'pages/register';
import ResetPassword from 'pages/reset-password';
import RequestDjBooking from 'pages/djs/dj/pages/request-dj-booking';
import Sales, {
    SongManagement,
    ContentUpload
} from 'pages/settings/pages/artist';
import Song from 'pages/song';
import Settings from 'pages/settings';
import Show from 'pages/shows/show';
import Shows from 'pages/shows';
import ShowCreate from 'pages/shows/show/pages/create';
import ShowPreview from 'pages/shows/show/pages/preview';
import ShowPayment from 'pages/shows/show/pages/payment';
import OrganizerShows, {
    Tickets as OrganizerShowTickets,
    Coupons as OrganizerShowCoupons
} from 'pages/settings/pages/organizer/shows';
import OrganizerShowPayout, {
    StripeAccount
} from 'pages/settings/pages/organizer/shows/payout';
import PushNotifications from 'pages/settings/pages/push-notifications';
import UserBooking from 'pages/settings/pages/user/booking';
import UserBookings from 'pages/settings/pages/user';
import Queue from 'pages/queue';
import VerifyAccount from 'pages/verify-account';
import type { RouteValues } from './routes';
import ROUTES from './routes';

type RoutesToExclude = typeof ROUTES.RECORD_LABELS;

type RouteKeysWithExcludedItems = Exclude<RouteValues, RoutesToExclude>;

const PageRoutes: Record<RouteKeysWithExcludedItems, PageRoute> = {
    [ROUTES.HOME]: {
        component: Home,
        key: 'home',
        title: 'Home',
        className: 'home',
        loader: homeLoader
    },
    [ROUTES.ALBUM]: {
        component: Album,
        key: 'album',
        access: ['user'],
        indexable: true,
        title: 'Album',
        props: {
            showBackButton: false
        },
        // @ts-ignore
        loader: albumLoader
    },
    [ROUTES.ARTIST]: {
        component: Artist,
        key: 'artist',
        access: ['user'],
        indexable: true,
        title: 'Artist',
        className: 'artist'
    },
    [ROUTES.ARTIST_VERIFICATION]: {
        component: ArtistVerification,
        key: 'artist-verification',
        access: ['artist'],
        title: 'Account Verification',
        className: 'artist-verification',
        maxWidth: 'sm'
    },
    [ROUTES.BROWSE]: {
        component: Browse,
        key: 'browse',
        access: ['user'],
        indexable: true,
        title: 'Browse',
        className: 'browse'
    },
    [ROUTES.REGISTER]: {
        component: Register,
        key: 'register',
        title: 'Register',
        alignCenter: true,
        hideChrome: true
    },
    [ROUTES.LOGIN]: {
        component: Login,
        key: 'login',
        title: 'Login',
        alignCenter: true,
        hideChrome: true
    },
    [ROUTES.DISCOVER]: {
        component: Discover,
        key: 'discover',
        access: ['user'],
        indexable: true,
        title: 'Discover'
    },
    [ROUTES.RECOMMENDED]: {
        component: Recommended,
        key: 'recommended',
        indexable: true,
        title: 'Recommended'
    },
    [ROUTES.RECENTLY_ADDED]: {
        component: RecentlyAdded,
        key: 'recently-added',
        access: ['user'],
        indexable: true,
        title: 'Recently Added'
    },
    [ROUTES.RECENTS]: {
        component: Recents,
        key: 'recents',
        access: ['user'],
        indexable: true,
        maxWidth: false,
        title: 'Recents',
        tabs: [
            {
                label: 'Recent Songs'
            },
            {
                label: 'Recent Playlists'
            }
        ]
    },
    [ROUTES.ALBUMS]: {
        component: Albums,
        key: 'albums',
        access: ['user'],
        indexable: true,
        title: 'Albums',
        className: 'albums'
    },
    [ROUTES.GENRES]: {
        component: Genres,
        key: 'genres',
        access: ['user'],
        indexable: true,
        title: 'Genres',
        className: 'genres'
    },
    [ROUTES.GENRE]: {
        component: Genre,
        key: 'genre',
        access: ['user'],
        indexable: true,
        title: 'Genre',
        className: 'genre',
        props: {
            showBackButton: false
        }
    },
    [ROUTES.SETTINGS]: {
        component: Settings,
        key: 'settings',
        access: ['user'],
        title: 'Settings'
    },
    [ROUTES.SETTINGS_PROFILE]: {
        component: Profile,
        key: 'profile',
        access: ['user'],
        title: 'Profile',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DJ_BOOKINGS]: {
        component: DjBookings,
        key: 'dj-bookings',
        access: ['dj'],
        title: 'DJ Bookings'
    },
    [ROUTES.ABOUT]: {
        component: About,
        key: 'about',
        title: 'About'
    },
    [ROUTES.FAQ]: {
        component: Faq,
        key: 'faq',
        title: 'Frequently Asked Questions'
    },
    [ROUTES.LIBRARY]: {
        component: Library,
        key: 'library',
        access: ['user'],
        title: 'Library',
        className: 'library',
        maxWidth: false,
        tabs: [
            {
                label: 'Playlists'
            },
            // {
            //     label: 'Artists',
            //     props: {
            //         disabled: true
            //     }
            // },
            // {
            //     label: 'Albums',
            //     props: {
            //         disabled: true
            //     }
            // },
            {
                label: 'Songs'
            }
        ]
    },
    [ROUTES.MUSIC_MIX]: {
        component: MusicMix,
        key: 'music-mix',
        title: 'Music Mix'
    },
    [ROUTES.FORGOT_PASSWORD]: {
        component: ForgotPassword,
        key: 'forgot-password',
        title: 'Forgot Password',
        alignCenter: true,
        hideChrome: true
    },
    [ROUTES.DASHBOARD]: {
        component: Dashboard,
        key: 'dashboard',
        access: ['admin', 'manager'],
        title: 'Dashboard',
        className: 'dashboard',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DASHBOARD_MUSIC]: {
        component: DashboardMusic,
        key: 'dashboard-music',
        access: ['admin'],
        title: 'Music Management',
        className: 'dashboard-music',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DASHBOARD_CONTENTS]: {
        component: DashboardContents,
        key: 'dashboard-content',
        access: ['admin', 'manager'],
        title: 'Content Management',
        className: 'dashboard-music',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.CART]: {
        component: Cart,
        key: 'cart',
        access: ['user'],
        indexable: true,
        title: 'Cart',
        className: 'cart',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.CART_PAYMENT]: {
        component: CartPayment,
        key: 'cart-payment',
        access: ['user'],
        indexable: true,
        title: 'Cart Purchase',
        className: 'cart-payment',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DASHBOARD_USERS]: {
        component: DashboardUsers,
        key: 'dashboard-setting',
        access: ['admin', 'manager'],
        title: 'User Management',
        className: 'dashboard-users',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DASHBOARD_PUSH_NOTIFICATIONS]: {
        component: DashboardPushNotifications,
        key: 'dashboard-push-notifications',
        className: 'dashboard-push-notifications',
        access: ['admin', 'manager'],
        title: 'Push Notifications'
    },
    [ROUTES.RESET_PASSWORD]: {
        component: ResetPassword,
        key: 'reset-password',
        title: 'Reset Password',
        alignCenter: true,
        hideChrome: true
    },
    [ROUTES.ACTIVATION]: {
        component: Activation,
        key: 'activation',
        title: 'Account Activation'
    },
    [ROUTES.ARTISTS]: {
        component: Artists,
        key: 'artists',
        access: ['user'],
        indexable: true,
        title: 'Artists',
        className: 'artists'
    },
    [ROUTES.DJS]: {
        component: Djs,
        key: 'djs',
        access: ['user'],
        indexable: true,
        title: 'DJs',
        className: 'djs'
    },
    [ROUTES.DJ]: {
        component: Dj,
        key: 'dj',
        indexable: true,
        title: 'DJ',
        className: 'dj'
    },
    [ROUTES.ORGANIZER_SHOW_PREVIEW]: {
        component: ShowPreview,
        key: 'preview-show',
        access: ['organizer'],
        title: 'Preview Show',
        className: 'show-preview',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.ORGANIZER_SHOW_CREATE]: {
        component: ShowCreate,
        key: 'show-create',
        access: ['organizer'],
        title: 'Create Show',
        className: 'show'
    },
    [ROUTES.ORGANIZER_SHOW_MODIFY]: {
        component: ShowCreate,
        key: 'show-modify',
        access: ['organizer'],
        restrictDirectAccess: (location, showId) => {
            if (
                (location?.state as LocationState)?.from !== 'preview' &&
                (showId as ShowId)
            ) {
                return getShowPreviewUri({
                    showId: showId as ShowId
                });
            }

            return false;
        },
        title: 'Modify Show',
        className: 'show'
    },
    [ROUTES.ORGANIZER_SHOW_PAYOUT]: {
        component: OrganizerShowPayout,
        key: 'payout',
        access: ['organizer'],
        title: 'Payout',
        className: 'payout',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.SONG]: {
        component: Song,
        key: 'song',
        indexable: true,
        title: 'Song',
        props: {
            showBackButton: false
        }
    },
    [ROUTES.STRIPE_ACCOUNT]: {
        component: StripeAccount,
        key: 'stripe-account',
        access: ['organizer'],
        title: 'Stripe Account'
    },
    [ROUTES.SHOW_PAYMENT]: {
        component: ShowPayment,
        key: 'show-payment',
        access: ['user'],
        title: 'Ticket Purchase',
        className: 'payment',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.SHOW]: {
        component: Show,
        key: 'show',
        title: 'Show Details',
        className: 'book-show',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.SHOWS]: {
        component: Shows,
        key: 'shows',
        title: 'Shows',
        className: 'shows'
    },
    [ROUTES.ORGANIZER_SHOWS]: {
        component: OrganizerShows,
        key: 'organizer-shows',
        access: ['organizer'],
        title: 'My Shows',
        className: 'show-view',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.ORGANIZER_SHOW_TICKETS]: {
        component: OrganizerShowTickets,
        key: 'users',
        access: ['organizer'],
        title: 'Show Tickets',
        className: 'show-view',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.ORGANIZER_SHOW_COUPONS]: {
        component: OrganizerShowCoupons,
        key: 'organizer-shows-coupons',
        access: ['organizer'],
        title: 'Show Coupons',
        props: {
            showBackButton: true
        },
        maxWidth: 'sm'
    },
    [ROUTES.ARTIST_SONG_MANAGEMENT]: {
        component: SongManagement,
        key: 'artist-song-management',
        access: ['artist'],
        title: 'Song management',
        className: 'song-management',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.ARTIST_SALES]: {
        component: Sales,
        key: 'artist-sales',
        access: ['artist'],
        title: 'Song sales',
        className: 'show-view',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.QUEUE]: {
        component: Queue,
        key: 'queue',
        access: ['user'],
        title: 'Queue',
        props: {
            showBackButton: true
        },
        maxWidth: false
    },
    [ROUTES.LIBRARY_PLAYLISTS]: {
        component: UserOrDjPlaylist,
        key: 'library-playlist',
        access: ['user'],
        title: 'Playlists'
    },
    [ROUTES.DJ_PLAYLISTS]: {
        component: Playlist,
        key: 'dj-playlist',
        title: 'DJ Playlist'
    },
    [ROUTES.PLAYLISTS]: {
        component: Playlist,
        key: 'playlist',
        title: 'Playlist'
    },
    [ROUTES.ARTIST_CONTENT_UPLOAD]: {
        component: ContentUpload,
        key: 'artist-content-upload',
        access: ['artist'],
        title: 'Content Upload',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.REQUEST_DJ_BOOKING]: {
        component: RequestDjBooking,
        access: ['user'],
        key: 'dj-booking',
        title: 'Book Dj'
    },
    [ROUTES.DJ_BOOKING]: {
        component: DjBooking,
        access: ['user'],
        key: 'dj-booking',
        title: 'DJ Booking',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.DJ_BOOKING_PAYOUT]: {
        component: BookingPayout,
        key: 'booking-payout',
        access: ['dj'],
        title: 'payout',
        className: 'payout',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.USER_BOOKINGS]: {
        component: UserBookings,
        access: ['user'],
        key: 'dj-bookings',
        title: 'DJ Bookings',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.USER_BOOKING]: {
        component: UserBooking,
        access: ['user'],
        key: 'dj-booking',
        title: 'DJ Booking',
        props: {
            showBackButton: true
        }
    },
    [ROUTES.VERIFY_ACCOUNT]: {
        component: VerifyAccount,
        key: 'verify-account',
        title: 'Verify Account',
        alignCenter: true,
        hideChrome: true
    },
    [ROUTES.PUSH_NOTIFICATIONS]: {
        component: PushNotifications,
        access: ['user'],
        key: 'push-notifications',
        title: 'Notifications',
        props: {
            showBackButton: true
        }
    }
};

export type PageRouteKeys = keyof typeof PageRoutes;
export default PageRoutes;
