import type {
    AxiosResponse,
    AxiosRequestTransformer,
    AxiosResponseTransformer,
    AxiosRequestConfig,
    AxiosError,
    InternalAxiosRequestConfig,
    AxiosRequestHeaders
} from 'axios';
import axios from 'axios';
import qs from 'qs';
// @ts-ignore: partially typed thirdparty library
import snakeize from 'snakeize';
import { v4 as uuid } from 'uuid';
import Cookies from 'overrides/js-cookie';
import { FORM_URLENCODED } from 'enums/content-type';
import config from 'config';
import { mapKeyToSnakeCase } from 'helpers/map-key-to-snake-case';
import { mapKeyToCamelCase } from 'helpers/map-key-to-camel-case';
import { isObjectOrArray } from 'helpers/is-object-or-array';
import type { FetchResponseError } from 'types/fetch';
import URIS from 'queries/uris.json';
import logger from 'utilities/logger';
import { captureException, configureScope } from 'overrides/sentry';
import type { Scope } from 'overrides/sentry';
import type { CorrelationId } from 'types';
import { getSessionId } from 'helpers/app';

const Header: {
    accessToken?: string;
    refreshToken?: string;
} = {};

const serializer = (data: object) => {
    return qs.stringify(data, { arrayFormat: 'brackets' });
};

const transformer = (data: object, stringify = false) => {
    const transformed = isObjectOrArray(data) ? snakeize(data) : data;

    if (stringify) {
        return serializer(transformed);
    }

    return transformed;
};

const paramsSerializer = (params: object) => {
    return serializer(mapKeyToSnakeCase(params));
};

const transformRequest: AxiosRequestTransformer[] = [].concat(
    // @ts-ignore: partially typed thirdparty library
    (data: any, headers: AxiosRequestHeaders) => {
        return transformer(
            data,
            // @ts-ignore: partially typed thirdparty library
            [FORM_URLENCODED].includes(headers['Content-Type'])
        );
    },
    axios.defaults.transformRequest
);

const transformResponse: AxiosResponseTransformer[] = [].concat(
    // @ts-ignore: partially typed thirdparty library
    axios.defaults.transformResponse,
    mapKeyToCamelCase
);

const axiosInstance = axios.create({
    baseURL: `${globalThis.location.protocol}//${config.API_HOST}/`,
    timeout: 10 * 1000,
    headers: {
        Accept: 'application/json'
    },
    paramsSerializer,
    transformRequest,
    transformResponse
});

export type AxiosResponseWithData<T> = AxiosResponse<T> & {
    data: T;
};

export type AxiosConfig =
    | AxiosRequestConfig<
          | {
                accessToken?: string;
                refreshToken?: string;
            }
          | undefined
      >
    | undefined;

const onRequestError = (e: AxiosError<unknown>) => {
    const { config, response, code } = e;

    captureException(e, {
        tags: {
            url: config?.url!,
            method: config?.method
        },
        extra: {
            body: config?.data,
            params: config?.params,
            data: response?.data,
            code: code
        }
    });

    const error: FetchResponseError = {
        message: e.message ?? `api failure ${config?.url}`,
        code: e.request?.status ?? 500,
        status: 'error',
        url: config?.url!
    };

    return Promise.reject(error);
};

const onResponseError = (e: AxiosError<FetchResponseError>) => {
    const { config, response, code } = e;

    const isTokenCall = URIS.token === config?.url;

    if (isTokenCall && config?.method === 'post') {
        Cookies.remove('shouldAutoLogin');
    }

    if (e.name !== 'AbortError') {
        captureException(e, {
            tags: {
                url: config?.url!,
                method: config?.method
            },
            extra: {
                body: config?.data,
                params: config?.params,
                data: response?.data,
                code: code
            }
        });
    }

    const error: FetchResponseError = {
        message: e.message ?? `api failure ${config?.url}`,
        code: response?.status ?? 500,
        status: 'error',
        url: config?.url!,
        ...response?.data
    };

    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<object>) => {
        // Do something before request is sent
        const method = config.method ?? 'get';
        const isTokenCall = URIS.token === config.url;

        if (isTokenCall && method !== 'get') {
            delete Header.accessToken;
        }

        if (Header.accessToken && config.headers) {
            config.headers.Authorization = `Bearer ${Header.accessToken}`;
        }

        if (isTokenCall) {
            const rememberMe = Cookies.get('rememberMe') !== 'false';

            config.data = {
                rememberMe,
                ...config.data
            };
        }

        config.headers!['X-Session-ID'] = getSessionId();

        const correlationId = uuid() as CorrelationId;

        config.headers!['X-Correlation-ID'] = correlationId;

        configureScope((scope: Scope) => {
            scope.setTag('correlation_id', correlationId);
        });

        return config;
    },
    onRequestError
);

axiosInstance.interceptors.response.use(response => {
    const { data, config } = response;
    const url = config.url || '';

    const requestData = mapKeyToCamelCase(qs.parse(config.data));
    const { accessToken, refreshToken } = data;
    const isTokenCall = URIS.token === url;
    const isLogout = URIS.users.logout === url;

    // clear tokens on logout
    if (isLogout) {
        delete Header.accessToken;
        delete Header.refreshToken;
    }

    // Do something with response data
    if (accessToken) {
        Header.accessToken = accessToken;
    }

    if (refreshToken) {
        Header.refreshToken = refreshToken;
    }

    if (isTokenCall && config.method === 'post') {
        const { username, email, grantType } = requestData;
        const rememberMe = requestData.rememberMe === 'true';

        if (rememberMe && grantType !== 'refresh_token') {
            const emailOrUsername = username || email;

            if (emailOrUsername) {
                Cookies.set('username', emailOrUsername);
            } else {
                logger.warn(`expected user email, but got ${emailOrUsername}`);
            }
        }

        if (rememberMe && data.refreshToken) {
            Cookies.set('shouldAutoLogin', true.toString(), {
                expires: 6 * 30 - 1 // 6 months - 1 day
            });
        } else {
            Cookies.remove('shouldAutoLogin');
        }
    }

    if (data.data === undefined) {
        data.data = null;
    }

    return data;
}, onResponseError);

export default axiosInstance;
