/* eslint-disable no-console */
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useEventsQuery } from 'queries/global';
import type { AppNotificationEvent } from 'types/app-event';
import { isCypress } from 'helpers/env';
import { getRemoteConfigStringValue } from 'overrides/firebase.remote-config';
import routes from 'shared/routes';

const interactedWithEventTypes = ['close', 'click'];

export const useNotification = () => {
    const [eventOrCounterFound, setEventOrCounterFound] =
        useState<boolean>(false);
    const [currentNotification, setCurrentNotification] = useState<string>();
    const { data, isSuccess } = useEventsQuery();
    const location = useLocation();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const notifications = getRemoteConfigStringValue('Notifications')
        .split(',')
        .map(str => str.trim());

    // check if current route is LOGIN, REGISTER, FAQ
    const isIgnoredRoutes =
        routes.LOGIN === location.pathname ||
        routes.REGISTER === location.pathname ||
        routes.FAQ === location.pathname;

    useEffect(() => {
        const interactedWithNotifications: string[] = [];
        let filteredNotifications: string[] = [...notifications];

        if (isSuccess) {
            notifications.forEach(notification => {
                (data as AppNotificationEvent[])?.forEach(event => {
                    // if event matches notification
                    if (event.data?.name === notification) {
                        // if the notification has been interaction with
                        if (
                            interactedWithEventTypes.includes(event.data?.type)
                        ) {
                            interactedWithNotifications.push(notification);
                        }
                    }
                });

                filteredNotifications = filteredNotifications.filter(
                    notification => {
                        return !interactedWithNotifications.includes(
                            notification
                        );
                    }
                );
            });

            // TODO [2024-10-30]: can we make this a random selection?
            setCurrentNotification(filteredNotifications[0]);

            if (
                !isCypress() &&
                !isIgnoredRoutes &&
                filteredNotifications.length !== 0
            ) {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }

                timerRef.current = setTimeout(() => {
                    setEventOrCounterFound(true);
                }, 30000);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, notifications]);

    return { eventOrCounterFound, currentNotification };
};
